import React from 'react';
import PropTypes from 'prop-types';
import Layout from '../components/Layout';
import PostPreview from '../components/PostPreview';
import { css } from '@emotion/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Colors from '../components/Colors';
import { faHashtag } from '@fortawesome/free-solid-svg-icons';
import CategoryHelper from '../helpers/Category';

// Components
import { graphql } from 'gatsby';

const Tags = ({ pageContext, data }) => {
  const { tag } = pageContext;
  const { edges } = data.allMdx; // totalCount is available

  return (
    <Layout
      jumbo={true}
      pageTitle={`TOPIC: ${tag.toUpperCase()} articles.`}
      pageDescription={`All of our articles about topic: ${tag.toUpperCase()}. Read ChristianBytes.com today.`}
    >
      <h1
        css={css`
          color: ${Colors.main} !important;
          font-size: 2rem;
          text-align: center;
          text-transform: uppercase;
          margin-bottom: 2rem;
          border-bottom: 2px solid ${Colors.main};
        `}
      >
        <FontAwesomeIcon
          css={css`
            color: ${Colors.main} !important;
            margin-right: 0.5rem;
          `}
          icon={faHashtag}
          size={'sm'}
          width="28"
        />
        {tag}
      </h1>
      <ul
        css={css`
          display: -webkit-box;
          display: flex;
          flex-wrap: wrap;
          list-style: none;
          margin: 0;
          padding: 0;
          justify-content: center;
        `}
      >
        {edges.map(({ node }) => {
          const { slug } = node.frontmatter;
          let post = node.frontmatter;
          post.fileLocation = CategoryHelper.getCategoryLocation(
            node.frontmatter.category,
            node.frontmatter.slug
          );
          return <PostPreview key={slug} post={post}></PostPreview>;
        })}
      </ul>
    </Layout>
  );
};

Tags.propTypes = {
  pageContext: PropTypes.shape({
    tag: PropTypes.string.isRequired,
  }),
  data: PropTypes.shape({
    allMdx: PropTypes.shape({
      totalCount: PropTypes.number.isRequired,
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            frontmatter: PropTypes.shape({
              title: PropTypes.string.isRequired,
            }),
            fields: PropTypes.shape({
              slug: PropTypes.string.isRequired,
            }),
          }),
        }).isRequired
      ),
    }),
  }),
};

export default Tags;

export const pageQuery = graphql`
  query($tag: String) {
    allMdx(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        frontmatter: { tags: { in: [$tag] }, status: { eq: "PUBLISHED" } }
      }
    ) {
      totalCount
      edges {
        node {
          frontmatter {
            title
            author
            category
            date
            slug
            status
            excerpt
            private
            metaDescription
            previewTitle
            canonicalurl
            ogpagetype
            tags
            image {
              childImageSharp {
                fluid(maxWidth: 300, maxHeight: 300, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`;
